import React from "react";
import { useMediaQuery } from "react-responsive";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from "gatsby";
import Container from "common/components/UI/Container";
import Image from "common/components/Image";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import GatsbyImage from "common/components/GatsbyImage";
import icon11 from "common/assets/image/saasCreative/icons/11.png";
import icon12 from "common/assets/image/saasCreative/icons/12.png";
import Section, {
  Grid,
  Figure,
  Content,
  SectionHeading,
  FeatureGroup,
  FeatureItem,
} from "./experience.style";

const Illustration = ({ src, ...rest }) => (
  <Fade up>
    <Figure>
      <GatsbyImage src={src} {...rest} />
    </Figure>
  </Fade>
);

const WalletExperience = () => {
  const isMobileOrTablet = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const image = useStaticQuery(graphql`
    query {
      analytics: file(relativePath: { eq: "image/saasCreative/Approve.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  const imageSrc =
    (image.analytics !== null) | undefined
      ? image.analytics.childImageSharp.gatsbyImageData
      : {};

  return (
    <Section>
      <Container width="1400px">
        <Grid>
          {isMobileOrTablet && (
            <Illustration
              src={imageSrc}
              alt="analytics"
              style={{
                border: "1px solid #eaeaea",
                boxShadow: "rgb(149 157 165 / 40%) 0px 0px 24px 0px",
              }}
            />
          )}
          <Fade up>
            <Content>
              <SectionHeading>
                <Text className="subtitle" content="Invoice approval" />
                <Heading content="Approval made simple" />
                <Text
                  className="description"
                  content="Quick and easy transfer of invoices and related documents, enabling managers to approve invoices faster and more efficiently."
                />
              </SectionHeading>
              <FeatureGroup>
                <FeatureItem>
                  <figure>
                    <Image src={icon11} alt="clock icon" />
                  </figure>
                  <div>
                    <Heading as="h4" content="Increased efficiency" />
                    <Text
                      className="description"
                      content="Many of the time-consuming and repetitive tasks can be automated, freeing up time for managers to focus on more strategic tasks"
                    />
                  </div>
                </FeatureItem>
                <FeatureItem>
                  <figure>
                    <Image src={icon12} alt="folder icon" />
                  </figure>
                  <div>
                    <Heading as="h4" content="Better collaboration" />
                    <Text
                      className="description"
                      content="Allows managers and team members to communicate and collaborate more effectively on the invoicing process"
                    />
                  </div>
                </FeatureItem>
              </FeatureGroup>
            </Content>
          </Fade>
          {!isMobileOrTablet && (
            <Illustration
              src={imageSrc}
              alt="analytics"
              style={{
                border: "1px solid #eaeaea",
                boxShadow: "rgb(149 157 165 / 40%) 0px 0px 24px 0px",
              }}
            />
          )}
        </Grid>
      </Container>
    </Section>
  );
};

export default WalletExperience;
