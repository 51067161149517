import React from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import Seo from "components/seo";
import { theme } from "common/theme/saasCreative";
import { DrawerProvider } from "common/contexts/DrawerContext";
import { ResetCSS } from "common/assets/css/style";

import BannerSection from "containers/SaasCreative/Banner";
import Navbar from "containers/SaasCreative/Navbar";
import Clients from "containers/SaasCreative/Clients";
import HowItWorks from "containers/SaasCreative/HowItWorks";
import AdvancedAnalytics from "containers/SaasCreative/AdvancedAnalytics";
import Features from "containers/SaasCreative/Features";
import WalletExperience from "containers/SaasCreative/WalletExperience";
import Testimonial from "containers/SaasCreative/Testimonial";
import Pricing from "containers/SaasCreative/Pricing";
import OurCommunity from "containers/SaasCreative/OurCommunity";
import Faq from "containers/SaasCreative/Faq";
import CallToAction from "containers/SaasCreative/CallToAction";
import Footer from "containers/SaasCreative/Footer";
import {
  GlobalStyle,
  ContentWrapper,
} from "containers/SaasCreative/saasCreative.style";
import "common/assets/css/react-slick.css";
import "rc-collapse/assets/index.css";
import CookieConsent, { Cookies } from "react-cookie-consent";

const Agency = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <>
          <Seo title="SmartPerformanceFlow - Vendor invoice approval made simple" />
          {/* end of head */}

          <ResetCSS />
          <GlobalStyle />
          {/* end of global and reset style */}

          {/* start app minimal landing */}
          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar viens="Tests" />
              </DrawerProvider>
            </Sticky>
            <BannerSection />
            {/* <Clients /> */}
            <HowItWorks />
            <AdvancedAnalytics />
            <Features />
            <WalletExperience />
            {/* <Testimonial /> */}
            <Pricing />
            {/* <OurCommunity /> */}
            <Faq />
            <CallToAction />
            <Footer />
          </ContentWrapper>
          {/* end of app minimal landing */}
        </>
      </ThemeProvider>
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
      >
        We use cookies to personalise content and ads, to provide social media
        features and to analyse our traffic. We also share information about
        your use of our site with our social media, advertising and analytics
        partners who may combine it with other information that you’ve provided
        to them or that they’ve collected from your use of their services.
      </CookieConsent>
    </>
  );
};
export default Agency;
