import React from "react";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from "gatsby";
import Container from "common/components/UI/Container";
import Heading from "common/components/Heading";
import Text from "common/components/Text";
import Input from "common/components/Input";
import Button from "common/components/Button";
import GatsbyImage from "common/components/GatsbyImage";
import Section, {
  BannerContentWrapper,
  BannerContent,
  Subscribe,
  Figure,
} from "./banner.style";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Banner = () => {
  const illustrations = useStaticQuery(graphql`
    query {
      dashboard: file(
        relativePath: { eq: "image/saasCreative/SPFMainScreen.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  const handleSubmit = (event) => {
    event.preventDefault();

    const myForm = event.target;
    const formData = new FormData(myForm);

    // console.log("FormData", event);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() =>
        toast.success("Noted! We will get in touch with you shortly!")
      )
      .catch((error) =>
        toast.error(
          "We were unable to register your e-mail. Please get in touch with us using the email address at the very bottom of this page."
        )
      );
  };

  // document.querySelector("form").addEventListener("submit", handleSubmit);

  return (
    <Section id="home">
      <ToastContainer />
      <Container width="1400px">
        <BannerContentWrapper>
          <BannerContent>
            <Fade up>
              <Heading content="Effortless invoice approvals, efficient financial management" />
            </Fade>
            <Fade up delay={100}>
              <Text content="Streamline your invoice approval process and enhance your financial performance with our solution." />
            </Fade>
            <Fade up delay={200}>
              <form
                name="GetDemo"
                method="POST"
                data-netlify="true"
                onSubmit={handleSubmit}
              >
                <input type="hidden" name="form-name" value="GetDemo" />
                <Subscribe>
                  <Input
                    inputType="email"
                    name="Email"
                    placeholder="Enter Email Address"
                    iconPosition="left"
                    aria-label="email"
                  />
                  <Button title="Request a demo" type="submit" />
                </Subscribe>
              </form>
            </Fade>
          </BannerContent>
          <Fade up delay={200}>
            <Figure>
              <GatsbyImage
                src={
                  (illustrations.dashboard !== null) | undefined
                    ? illustrations.dashboard.childImageSharp.gatsbyImageData
                    : {}
                }
                objectFit="contain"
                alt="Banner"
                style={{
                  border: "1px solid #eaeaea",
                  boxShadow: "rgb(149 157 165 / 40%) 0px 0px 24px 0px",
                }}
              />
            </Figure>
          </Fade>
        </BannerContentWrapper>
      </Container>
    </Section>
  );
};

export default Banner;
