import React from "react";
import Fade from "react-reveal/Fade";
import { Icon } from "react-icons-kit";
import { useStaticQuery, graphql } from "gatsby";
import { arrowRight } from "react-icons-kit/feather/arrowRight";
import Container from "common/components/UI/Container";
import Text from "common/components/Text";
import Link from "common/components/Link";
import Heading from "common/components/Heading";
import Section, { Grid, Figure, Content } from "./analytics.style";
import GatsbyImage from "common/components/GatsbyImage";

const AdvancedAnalytics = () => {
  const image = useStaticQuery(graphql`
    query {
      analytics: file(relativePath: { eq: "image/saasCreative/Flow.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 714
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <Section>
      <Container width="1400px">
        <Grid>
          <Fade up>
            <Figure>
              <GatsbyImage
                src={
                  (image.analytics !== null) | undefined
                    ? image.analytics.childImageSharp.gatsbyImageData
                    : {}
                }
                alt="thank you docs"
                style={{
                  border: "1px solid #eaeaea",
                  boxShadow: "rgb(149 157 165 / 40%) 0px 0px 24px 0px",
                }}
              />
            </Figure>
          </Fade>
          <Content>
            <Text className="subtitle" content="Automated approval flow" />
            <Heading content="Advanced and configurable multi-level approval flow" />
            <Text
              className="description"
              content="Create multiple pre-defined approval flows and assing them to vendors. This way all required approvals will always be in place."
            />
            {/* <Link href="#" className="explore">
              Explore details <Icon icon={arrowRight} />
            </Link> */}
          </Content>
        </Grid>
      </Container>
    </Section>
  );
};

export default AdvancedAnalytics;
